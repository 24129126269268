body {
  font-family: $font-family-base;
  font-size: $font-size-base;
  line-height: $line-height-base;
  color: black;
  background: white;
}

p {
  @media (max-width:1280px) {
    font-size: 13px
  }
}

.font2 {
  font-family: $headings-font-family;
}

.font1 {
  font-family: "Montserrat";
  font-weight: 400;
}


.page-header {
  margin: 50px 0;
  @extend .title_center;
}

.bg-1 {
  @media (max-width:$screen-sm-min) {
    h1 {
      text-shadow: 2px 2px 1px black,
        0px 0px 10px black;
    }
  }
}

.btn,
a,
button {
  transition: .5s;
  outline-style: none;
  outline-width: 0;
  outline-color: transparent;

  &:hover,
  &:focus,
  &:active {
    outline-style: none;
    outline-width: 0;
    outline-color: transparent;
  }
}

.btn {
  line-height: 1.5 !important;
}

.footer {
  text-align: center;
  background-color: $brand-primary;
  font-size: $font-size-base;
  color: white;
  min-height: 25rem;
  display: flex;
  align-items: center;

  a {
    color: white;

    &:hover {
      color: white;
    }
  }

  .footer_address {
    color: black;
  }

  @media(min-width: $screen-sm-min) {
    text-align: left;
  }
}

////////////// boutons

.btn-fleche {
  @extend .btn-accueil;
  background: $brand-primary;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: white;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-primary;
  //margin-bottom: 50px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;


  &:hover {
    background: white;
    color: $brand-primary;
    border-radius: 50px;
    border: solid 1px $brand-primary;
    padding-right: 70px;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: $brand-primary;
    -webkit-transition: all .8s ease-out .0s;
    transition: all .8s ease-out .0s;
  }
}

.btn-fleche-2 {
  @extend .btn-accueil;
  background: white;
  white-space: normal;
  position: relative;
  padding: 10px 50px;
  border: 2px solid;
  color: $brand-second;
  font-weight: bold;
  border-radius: 50px;
  border: solid 1px $brand-second;
  //margin-bottom: 50px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out;

  &:hover {
    background: $brand-primary;
    color: white;
    border-radius: 50px;
    border: solid 1px $brand-primary;
    padding-right: 70px;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out;
  }

  &::after {
    position: absolute;
    margin-left: 20px;
    content: "\f061";
    font-family: fontAwesome;
    color: white;
    -webkit-transition: all .8s ease-out .0s;
    transition: all .8s ease-out .0s;
  }
}

.btn-fleche-inverse {
  @extend .btn-fleche;
  border: 2px solid;
  color: $brand-primary;
  border: solid 1px $brand-primary;

  &:hover {
    background: $brand-primary;
    color: white;

  }

  &::after {
    color: white;
  }
}

.btn-perso {
  @extend .btn-accueil;
  background: $brand-primary;
  color: white;
  border: solid 2px white;
  border-radius: 50px;
  padding: 15px 50px;
  margin: 15px auto;

  &:hover {
    background: white;
    color: $brand-primary;
    border-radius: 50px;
    border: solid 2px $brand-primary;
  }

  .fa {
    margin-right: 10px;
  }
}

.btn-perso-inverse {
  background: white;
  color: $brand-primary;
  border: solid 1px $brand-primary;
  border-radius: 50px;
  padding: 15px 50px;
  margin: 15px auto;

  .fa {
    margin-right: 10px;
  }
}

/* ==========================================================================
      Global
      ========================================================================== */
.title_left {
  @extend .text-left;
  //   margin: 0 0 20px 0;
  //   padding: 0 0 25px 0;
  position: relative;
  color: $brand-second;
  //   text-transform: uppercase;
  line-height: 1.5;


  small {
    display: block;
    line-height: 1.5;
    color: $brand-second;
    font-size: 50%;
  }

  //   &::after {
  //       content: '';
  //       position: absolute;
  //       bottom: 0;
  //       left: 0;
  //       width: 4rem;
  //       height: 4px;
  //       background-color: $brand-primary;
  //   }
}

.title_left_2 {
  @extend .text-left;
  @extend .title_left;
  color: white;
  font-weight: bolder;

  &::after {
    background-color: black;
  }
}


.title_center {
  @extend .text-center;
  margin: 0 0 20px 0;
  padding: 0 0 25px 0;
  position: relative;
  color: $brand-second;
  line-height: 1.5;
  // font-family: 'Josefin Sans', sans-serif;

  small {
    display: block;
    line-height: 1.5;
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 4rem;
    height: 2px;
    transform: translateX(-50%);
    background-color: $brand-second;
  }
}


.frame-1 {
  padding: 20px;
  margin: 20px;

  @media (max-width:$screen-xs-min) {
    padding: 10px;
    margin: 10px;
  }
}

.frame-2 {
  padding: 20px;

  @media (max-width:$screen-xs-min) {
    padding: 10px;
  }
}

.frame-3 {
  margin: 2%;

  @media (max-width:$screen-xs-min) {
    margin: 10px;
  }
}

.smap {

  .map {
    padding-bottom: 500px;
  }

  .block1 {
    padding: 3%;
    color: white;
    background: $brand-primary;
  }
}

.img-rounded {
  border-radius: 15px;
}



.reverse {
  @media (max-width:$screen-sm-min) {
    flex-direction: column-reverse;
  }
}

@media (min-width: $screen-md-min) {
  .figure__compo-1 {
    position: relative;
    @include padding(1rem);
    height: 40rem;
    max-height: 100%;
    width: 40rem;
    max-width: 100%;

    >img {
      position: absolute;
      object-fit: cover;
    }

    >img:nth-child(1) {
      top: 0;
      left: 0;
      width: 70%;
      height: 55%;
    }

    >img:nth-child(2) {
      bottom: 0;
      right: 0;
      width: 70%;
      height: 55%;
    }
  }
}

@media (max-width: 992px) {
  .rellax {
    transform: translate3d(0, 0, 0) !important;
  }
}

.blockquote-2 {
  // padding: 15px 30px;
  margin: 0 0 30px;
  //   font-size: 16px;
  border-left: 5px solid $brand-second;
  margin-left: 25px;

  // font-weight:500;
}

//.nav>li>a {
//padding: 10px 15px;

//	@media (max-width:1280px) {
//	font-size: 12px;
//	padding: 10px 10px;
//}
//}

//////////////////////////////////////////////////////
//////////////////////////////////////////////////////
//////////////////////////////////////////////////////


.header1 {

  .header-content {
    min-height: 70vh;
    background: url(../images/charte/header1.jpg) no-repeat center;
    background-size: cover;

    @media (max-width:$screen-sm-min) {
        min-height: 0vh;
        padding-top: 100px;
        padding-bottom: 100px;
    }
 
    
  }
  .block1 {
    background: rgba(97, 100, 105, 0.98);
    padding-right: 5%;
    padding-left: 5%;
    margin-left: -3%;

      @media (max-width:$screen-sm-min) {
        background: rgba(97, 100, 105, 0.85);
      }

  }

}

.header2 {

  .header-content {
    min-height: 50vh;
    background: url(../images/charte/bureau-1.jpg) no-repeat center;
    background-size: cover;

      @media (max-width:$screen-sm-min) {
        min-height: 0vh;
        padding-top: 100px;
        padding-bottom: 100px;
    }
  }

  .block1 {
    background: rgba(97, 100, 105, 0.98);
    padding-right: 5%;
    padding-left: 5%;
    margin-left: -3%;

      @media (max-width:$screen-sm-min) {
        background: rgba(97, 100, 105, 0.85);
      }

  }

}

.s1 {

  .block1 {
    background: url(../images/charte/img1.jpg) no-repeat center bottom;
    background-size: cover;
    margin-right: 5px;
  }

  .titre {
    background: rgba(97, 100, 105, 0.98);
    padding-right: 5%;
    padding-left: 5%;
    // margin-left: -3%;
  }

  .text {

    max-width: 80% !important;

    @media (max-width:1280px) {
      max-width: 95% !important;
    }
  }

}

.s-quote {
  .container {
    background: url(../images/charte/quote2.png) no-repeat left top;
  }

  .content {
    max-width: 80% !important;
    margin: auto;
  }
}



.s-portrait-1 {

  .block-photo {
    background: url(../images/charte/benoit.jpg) no-repeat center left;
    background-size: cover;
    min-height:30vh;


    @media (max-width:$screen-sm-min) {
      min-height: 60vh;
    }

  }

  .block-text {}

}

.s-portrait-2 {

  .block-photo {
    background: url(../images/charte/tony.jpg) no-repeat center ;
    background-size: cover;
    min-height:30vh;

    @media (max-width:$screen-sm-min) {
      min-height: 60vh;
    }

  }

  .block-text {}

}

/////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////

.section-missions {
  background-color: $brand-primary;
  border-top: solid 1px $gray-lighter;
  border-bottom: solid 1px $gray-lighter;

  @media(min-width: $screen-md-min) {
    // margin: 0 -3rem;
    // padding: 3rem;
  }

  @media(min-width: $screen-lg-min) {
    // margin: 0 -5rem;
    // padding: 5rem;
  }
}

.section-actu {
  @extend .section-missions;
  background: white;
  border: none;
}

.article-quote {
  @extend .row;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  position: relative;

  // &::after {
  // 	content: '\f078';
  // 	font-family: 'fontAwesome';
  // 	position: absolute;
  // 	bottom: -6.5rem;
  // 	left: 50%;
  // 	margin-left: -3rem;
  // 	width: 6rem;
  // 	height: 6rem;
  // 	line-height: 5.4rem;
  // 	text-align: center;
  // 	background-color: $brand-second;
  // 	border: solid .3rem white;
  // 	border-radius: 50%;
  // 	color: white;
  // 	font-size: $font-size-h3;
  // 	box-shadow: 0 .5rem 2rem rgba(black, .2);
  // }

  >.item-quote {
    padding: 1.5rem;
    display: flex;
    align-items: stretch;

    >p {
      margin: 0;
      padding: 3rem;
      margin: 3rem auto;
      border: solid 1px $gray-lighter;
      background-color: white;
      border-left: solid .3rem $brand-second;
      font-family: $font-family-serif;
      font-size: $font-size-large;
      font-style: italic;
      color: $brand-primary;
    }
  }
}

.section-conception {
  @extend .section-missions;
  background: white;
  border: none;
  padding-top: 6rem;
}

.panel-item {
  background-color: #f7f7f7;
  margin-bottom: 3rem;

  &.realisation {
    background: url(../images/presentation/realisation.jpg) no-repeat center bottom #f9f9f9;
  }

  &.suivi {
    background: url(../images/presentation/suivi.jpg) no-repeat center bottom #f9f9f9;
  }

  >h3 {
    margin: 0;
    padding: 2.5rem 3rem;
    background-color: $brand-second;
    color: white;
  }

  >.panel-body {
    padding: 3rem;
  }

  h4 {
    font-size: $font-size-large;
    padding: 2.5rem 3rem;
    font-weight: 400;
    margin: 0;
    background-color: #ebebeb;
    border-bottom: solid 1px $gray-lighter;
  }

  >ul {
    @extend .list-unstyled;
    margin: 1.5rem 0;

    >li {
      padding: 1rem 3rem;
      // border-bottom: solid 1px $gray-lighter;

      &:last-child {
        border: none;
      }
    }
  }

  >ol {
    @extend .list-unstyled;
    counter-reset: conception;
    padding: 2rem 3rem;
    margin: 0;
    border-bottom: solid 1px $gray-lighter;
    background: url(../images/presentation/conception.jpg) no-repeat right bottom white;

    >li {
      counter-increment: conception;
      padding: .5rem 0 .5rem 3rem;
      position: relative;

      &::before {
        content: counter(conception);
        position: absolute;
        top: .4rem;
        left: 0;
        display: inline-block;
        height: 2.3rem;
        width: 2.3rem;
        line-height: 2.1rem;
        border: solid 1px $gray-lighter;
        text-align: center;
        margin-right: .8rem;
        background: white;
        color: $brand-primary;
        border-radius: 50%;
      }
    }
  }
}

.section-other {
  @extend .section-missions;
  background-color: rgba($brand-primary, 0.8);
  background: url(../images/presentation/fond-page.jpg) no-repeat center bottom;
  background-size: cover;

  >h3 {
    font-size: $font-size-h2;
    margin: 0 0 3rem;
  }

  ul {
    @extend .list-unstyled;
    margin: 0;

    >li {
      padding: 1rem 2rem;
      margin-bottom: 1rem;
      margin-left: 0rem;
      background-color: white;
      border-radius: 0 5rem 5rem 0;
      border: solid 1px $brand-second;
      position: relative;
      font-size: 14px;

      &::after {
        content: '\f105';
        font-family: 'fontAwesome';
        position: absolute;
        top: .82rem;
        left: -1rem;
        width: 2rem;
        height: 2rem;
        display: flex;
        align-items: center;
        justify-content: center;

        // line-height: 2.8rem;
        text-align: center;
        border-radius: 50%;
        background-color: white;
        border: solid 1px $brand-second;
        color: $brand-primary;
        box-shadow: -.1rem .1rem .1rem rgba(black, .1);
      }
    }
  }
}

// .filtrenb{
//   filter: grayscale(1);

//   &:hover{
//     filter: grayscale(0);
//   }
// }

.big-desc{
  font-weight:600;
  color: $brand-second;

}

.mb-p{
  margin-bottom:2px;
}

.galerie{
  
    border: solid 1px $brand-second;

}


.btn-default{
  border:solid 1px #ddd;
}