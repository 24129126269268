/* ==========================================================================
  Galerie-masonry
  ========================================================================== */

.thumbnail-anim {

  img {
    filter: grayscale(1);
    transition: all .5s;
  }

  &:hover img {
    transform: scale(1.05);
    filter: grayscale(0);
  }
}

.thumbnail-anim--2 {

  img {
    transition: transform .5s;
  }

  &:hover img {
    transform: scale(1.05);
  }
}
