/* ==========================================================================
	Main
	========================================================================== */

.main {
	@extend .clearfix;
	min-height: 50vh;
	overflow:hidden;
}

/* ==========================================================================
	Col Main
	========================================================================== */

.col-main {
	@extend .col-md-9;
	@extend .col-md-push-3;
	padding-bottom: 6rem;
}

/* ==========================================================================
	Col Aside
	========================================================================== */

.col-aside {
	@extend .col-md-3;
	@extend .col-md-pull-9;
	padding-top: 3rem;
}


/* ==========================================================================
	Bouton lien _config et passe
	========================================================================== */

	.links-config{
		position: fixed;
		bottom: 20px;
		left: 20px;
		z-index: 99999;
		margin-bottom: 0;
		padding: 9px 15px;

		background-color: rgba(#333, .3);

		.close{
			top : 0;
			right: .2rem;
			font-size: 18px;
		}
	}
