/* ==========================================================================
   Accueil
   ========================================================================== */

.header-accueil {
  @extend .text-center;
  background-color: #616469;
  overflow: hidden;
  padding-bottom: 10rem;
}

.logo-accueil {
  display: inline-block;
  transition: transform ease-in-out .3s;

  &:hover {
    transform: scale(1.05);
  }
  
  >img {    
    @extend .img-responsive;
    @extend .animated;
    @extend .fadeInDown;
    animation-duration: 2s;
    display: inline-block;
    margin: 0 auto;
    width: 25rem;
  }
}

.main-accueil {
  @extend .container-max;
  display: flex;
  flex-flow: row wrap;
  padding-bottom: 6rem;
  margin-top: -8rem;
  min-height: 50rem;
  
  >.col-flex {
    display: flex;
    align-items: stretch;
    width: 100%;
    min-height: 40rem;
    padding: 1.5rem;

    @media(min-width: $screen-sm-min) {
      width: 50%;
      min-height: 10rem;


      &:nth-child(1) {
        width: 40%;
      }

      &:nth-child(2) {
        width: 60%;
      }
      
      &:nth-child(3) {
        width: 100%;
      }

      &:nth-child(4) {
        width: 60%;
      }

      &:nth-child(5) {
        width: 40%;
      }
    }
    
    >a {
      @extend .thumbnail;
      margin: 0;
      border: none;
      width: 100%;
      background-color: black;
      box-shadow: 0 1rem 3rem rgba(black, .2);
      position: relative;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      text-align: center;

      &::after {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(black, .3);
        transition: all ease-in-out .5s;
      }
      
      >span {
        position: relative;
        z-index: 1;
        display: inline-block;
        padding: 2rem;
        font-size: $font-size-h2;
        color: white;
        text-transform: uppercase;
        font-weight: 700;
        transition: all ease-in-out .8s;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          right: 50%;
          bottom: 0;
          height: .3rem;
          opacity: 0;
          background-color: white;
          transition: all ease-in-out .8s;
        }
      }
      
      &:hover::after {
        background-color: transparent;
      }

      &:hover>span {
        letter-spacing: .3rem;
        text-shadow: 0 0 1rem rgba(black, .5);

        &::after {
          left: 2rem;
          right: 2rem;
          opacity: 1;
        }
      }
    }

    &:nth-child(1)>a {
      background: url(../images/accueil/professionnels.jpg) no-repeat center black;
      background-size: cover;
    }

    &:nth-child(2)>a {
      background: url(../images/accueil/particuliers.jpg) no-repeat center black;
      background-size: cover;
    }

    &:nth-child(3)>a {
      background: url(../images/accueil/realisations.jpg) no-repeat center black;
      background-size: cover;
    }

    &:nth-child(4)>a {
      background: url(../images/accueil/presentation.jpg) no-repeat center black;
      background-size: cover;
    }

    &:nth-child(5)>a {
      background: url(../images/accueil/contact.jpg) no-repeat center black;
      background-size: cover;
    }
  }
}

.footer-accueil {
  @extend .text-center;
  padding: 6rem 0;
  background-color: $brand-primary;

  color:white;

  h1 {
    font-size: 2.5rem;
  }
}

.col-footer-accueil-left {
  @extend .col-sm-6;
  @extend .text-left;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
  }
}

.col-footer-accueil-right {
  @extend .col-sm-6;
  @extend .text-right;
  
  @media (max-width: $screen-xs-max) {
    text-align: center;
    padding-top: 20px;
  }
}